
import React, {  useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import { MdOutlineClose as Cross } from "react-icons/md"
import { MdWest, MdEast, MdNorth, MdSouth } from "react-icons/md"
import { GrSchedule } from "react-icons/gr"
import { GiMountaintop } from "react-icons/gi"

import Perk from "./Perk"



const PerksModule = () => {
  
  useEffect(() => {
   /*  console.log("PerksModule MOUNT") */
    return () => {
      /* console.log("PerksModule UNMOUNT") */
      //stopTimeToChange();
    }
    /* window.addEventListener("scroll", handleScroll); */

  },[]);

  return (
    <PerksModuleStyles id="perksIndex" className="section section__padding">
      
      <div className="container container__tight">
        <Perk
          title="È raggiungibile solo a piedi con minimo 2 ore di cammino"
          content="scopri come"
          link="/dove#approfondisci"
        >
         
          <div className="iconBig">
            <GiMountaintop />
          </div>
        </Perk>
        <span className="perks__divider">
          {/* <Cross /> */}
          <div className="icon-top"><MdNorth /></div>
          <div className="text">Il rifugio Cognolo</div>
          <div className="icons"><MdWest /><MdEast /></div>
          <div className="icon-bottom"><MdSouth /></div>
        </span>
        <Perk
          title="È aperto tutto l'anno solo su prenotazione"
          content="prenota ora"
          link="/contatti#form_header"
        >
         
          <div className="iconBig">
            <GrSchedule />
          </div>
        </Perk>
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule