//import * as React from "react"
import React, { useEffect } from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModuleWrapper from "../components/BannerModule/BannerModuleWrapper"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import BasicTextPhotoModule from "../components/BasicTextPhotoModule/BasicTextPhotoModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import { graphql } from 'gatsby'


export default function IndexPage({ data }) {
  console.log("init")
  //const IndexPage = () => {
  //console.log("INDEX", data.allWp.edges[0].node.cognoloSettings.home_testi)
  const titles = ["Rifugio Cognolo", "Rifugio Cognolo", "Rifugio Cognolo", "Rifugio Cognolo"]
  const subtitles = ["un progetto di riqualificazione di uno spazio pubblico a cura dell'Associazione ALA - APS. ", "Un luogo dove fare esperienza e vivere la relazione con la natura in maniera diversa", "È raggiungibile solo a piedi con minimo 2 ore di cammino", "È aperto tutto l'anno solo su prenotazione"]
  const linkTexts = ["Scopri chi siamo", "Scopri le attività", "Come raggiungerci", "Prenota"]
  const linkUrls =["/chisiamo","/attivita","/dove","/contatti"]

  /* const nav = document.querySelector(".nav");
  console.log("navnav",nav) */

  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModuleWrapper
          max={3}
          titles={titles}
          subtitles={subtitles}
          linkTexts={linkTexts}
          linkUrls={linkUrls}
          images={data.allWp.edges[0].node.cognoloSettings.impostazioni.bgs}
         
        />
        { <BasicTextPhotoModule 
          titolo={data.allWp.edges[0].node.cognoloSettings.home_testi.titoloSezioneHome} 
          content={data.allWp.edges[0].node.cognoloSettings.home_testi.testiHome}
          image={data.allWp.edges[0].node.cognoloSettings.home_testi.immagineSezioneHome}
          /> }
        <PerksModule >
          
            <Perk title="The Title" content="The content"  />
          
        </PerksModule>
      </Layout>
    </>
  )
}

export const imagesWp = graphql`
{
  allWp {
    edges {
      node {
        cognoloSettings {
          
          home_testi {
            testiHome {
              paragrafo
            }
            titoloSezioneHome
            immagineSezioneHome {
              localFile{
                  childrenImageSharp {
                      gatsbyImageData(
                      
                      placeholder: BLURRED
                      formats: AUTO
                      layout: FULL_WIDTH
                      )
                  }
              }
          }
          }
          pageSlug
          pageTitle
          impostazioni {
            bgs {
              localFile {
                childrenImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.7
                    placeholder: BLURRED
                    formats: AUTO
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
}
`